import React, { createContext, useState, useEffect } from "react";

const ReadNewPolicyContext = createContext();

// Rewrite setItem function to fire window event when localstorage is written to
const originalSetItem =
  typeof localStorage !== "undefined" ? localStorage.setItem : null;

if (typeof localStorage !== "undefined") {
  localStorage.setItem = function(key, value) {
    const event = new Event("itemInserted");

    event.value = value;
    event.key = key;

    document.dispatchEvent(event);

    originalSetItem.apply(this, arguments);
  };
}

function ReadNewPolicyProvider(props) {
  const [newPolicyPopupActive, setNewPolicyPopupActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      let policyViewed =
        typeof localStorage !== "undefined"
          ? localStorage.getItem("policyViewed01")
          : null;
      if (
        window !== "undefined" &&
        (policyViewed === "true" ||
          window.location.href.includes("blog") ||
          window.location.href.includes("ed-guide") ||
          window.location.href.includes("about"))
      ) {
        setNewPolicyPopupActive(false);
      } else {
        setTimeout(() => {
          setNewPolicyPopupActive(true);
        }, 30000);
      }
    }, 1000);

    const updatePopupViewedState = ev => {
      if (ev.key === "policyViewed01" && ev.key === "true") {
        setNewPolicyPopupActive(false);
      }
      return;
    };
    if (typeof document !== "undefined") {
      document.addEventListener("itemInserted", updatePopupViewedState);
      return () => {
        document.removeEventListener("itemInserted", updatePopupViewedState);
      };
    }
  }, []);

  return (
    <ReadNewPolicyContext.Provider
      value={[newPolicyPopupActive, setNewPolicyPopupActive]}
    >
      {props.children}
    </ReadNewPolicyContext.Provider>
  );
}

export default ReadNewPolicyContext;

export { ReadNewPolicyProvider };
