// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-ed-guide-js": () => import("./../../../src/pages/ed-guide.js" /* webpackChunkName: "component---src-pages-ed-guide-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fb-js": () => import("./../../../src/pages/fb.js" /* webpackChunkName: "component---src-pages-fb-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mens-health-best-ed-pills-js": () => import("./../../../src/pages/mens-health-best-ed-pills.js" /* webpackChunkName: "component---src-pages-mens-health-best-ed-pills-js" */),
  "component---src-pages-mens-health-js": () => import("./../../../src/pages/mens-health.js" /* webpackChunkName: "component---src-pages-mens-health-js" */),
  "component---src-pages-mens-health-old-js": () => import("./../../../src/pages/mens-health-old.js" /* webpackChunkName: "component---src-pages-mens-health-old-js" */),
  "component---src-pages-privacy-disclosure-js": () => import("./../../../src/pages/privacy-disclosure.js" /* webpackChunkName: "component---src-pages-privacy-disclosure-js" */),
  "component---src-pages-science-js": () => import("./../../../src/pages/science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-treatments-aesthetics-js": () => import("./../../../src/pages/treatments/aesthetics.js" /* webpackChunkName: "component---src-pages-treatments-aesthetics-js" */),
  "component---src-pages-treatments-emtt-js": () => import("./../../../src/pages/treatments/emtt.js" /* webpackChunkName: "component---src-pages-treatments-emtt-js" */),
  "component---src-pages-treatments-js": () => import("./../../../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */),
  "component---src-pages-treatments-technology-js": () => import("./../../../src/pages/treatments/technology.js" /* webpackChunkName: "component---src-pages-treatments-technology-js" */),
  "component---src-pages-treatments-womens-sexual-wellness-js": () => import("./../../../src/pages/treatments/womens-sexual-wellness.js" /* webpackChunkName: "component---src-pages-treatments-womens-sexual-wellness-js" */),
  "component---src-templates-mdx-blog-template-js": () => import("./../../../src/templates/mdxBlogTemplate.js" /* webpackChunkName: "component---src-templates-mdx-blog-template-js" */)
}

